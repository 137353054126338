import React from "react";
import { withContext } from "~context";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import * as styles from "./index.module.scss";
import { VoicesLinkFooter } from "~components/assessment/voice-link-footer";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { Section } from "~components/templates/section";

const Component: React.FC = () => {
  const CONTACT_FORM_ID =
    "2VrF8TwHsoWuBNLF2ujgtsJl4jhaTmjJehw1FjEOUIQ8wgwbKBfFUlETYL07oEZ6H";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "セキュリティ診断事例紹介",
          description:
            "Flatt Securityのセキュリティ診断の事例インタビューを紹介します。お客様ごとに課題は様々ですが、課題解決としてFlatt Securityのどのような点が評価されているかがわかります。",
        }}
      >
        <div className={styles.VoiceRecommendation}>
          <div className={styles.VoiceRecommendation_Head}>
            <Common2PaneSectionLayout>
              <h1 className={styles.VoiceRecommendation_Title}>
                セキュリティ診断事例
              </h1>
              <p className={styles.VoiceRecommendation_TitleDescription}>
                Flatt Securityのセキュリティ診断の事例インタビューを紹介します。
                <br />
                お客様ごとに課題は様々ですが、課題解決としてFlatt
                Securityのどのような点が評価されているかがわかります。
              </p>
              <ol className={styles.VoiceRecommendation_Toc}>
                <li className={styles.VoiceRecommendation_TocItem}>
                  <a
                    href="#beginner"
                    className={styles.VoiceRecommendation_TocItemLink}
                  >
                    &#128304;
                    初めての診断実施に寄り添った提案・サポートを評価いただいた事例
                  </a>
                </li>
                <li className={styles.VoiceRecommendation_TocItem}>
                  <a
                    href="#cloud"
                    className={styles.VoiceRecommendation_TocItemLink}
                  >
                    &#127781;
                    クラウドプラットフォーム/サービスに固有の観点を含む診断を実施した事例
                  </a>
                </li>
                <li className={styles.VoiceRecommendation_TocItem}>
                  <a
                    href="#suggestion"
                    className={styles.VoiceRecommendation_TocItemLink}
                  >
                    &#x1f3af; 柔軟な診断プランの提供でお客様の課題を解決した事例
                  </a>
                </li>
                <li className={styles.VoiceRecommendation_TocItem}>
                  <a
                    href="#skill"
                    className={styles.VoiceRecommendation_TocItemLink}
                  >
                    &#128640;
                    提案・診断実施・報告書を通して高い技術力を実感した事例
                  </a>
                </li>
              </ol>
            </Common2PaneSectionLayout>
          </div>
          <div className={styles.VoiceRecommendation_Body}>
            <Common2PaneSectionLayout>
              <div
                id="beginner"
                className={styles.VoiceRecommendation_Category}
              >
                <h3 className={styles.VoiceRecommendation_Heading}>
                  <span className={styles.VoiceRecommendation_HeadingEmoji}>
                    &#128304;
                  </span>
                  <span className={styles.VoiceRecommendation_HeadingText}>
                    初めての診断実施に寄り添った提案・サポートを
                    <br
                      className={styles.VoiceRecommendation_HeadingChangeLine}
                    />
                    評価いただいた事例
                  </span>
                </h3>
                <AssessmentVoiceList slugs={["onecareer", "tameny"]} />
              </div>

              <div id="cloud" className={styles.VoiceRecommendation_Category}>
                <h3 className={styles.VoiceRecommendation_Heading}>
                  <span className={styles.VoiceRecommendation_HeadingEmoji}>
                    &#127781;
                  </span>
                  <span className={styles.VoiceRecommendation_HeadingText}>
                    クラウドプラットフォーム/サービスに
                    <br
                      className={styles.VoiceRecommendation_HeadingChangeLine}
                    />
                    固有の観点を含む診断を実施した事例
                  </span>
                </h3>
                <AssessmentVoiceList slugs={["smarthr", "en-japan"]} />
              </div>

              <div
                id="suggestion"
                className={styles.VoiceRecommendation_Category}
              >
                <h3 className={styles.VoiceRecommendation_Heading}>
                  <span className={styles.VoiceRecommendation_HeadingEmoji}>
                    &#x1f3af;
                  </span>
                  <span className={styles.VoiceRecommendation_HeadingText}>
                    柔軟な診断プランの提供でお客様の
                    <br
                      className={styles.VoiceRecommendation_HeadingChangeLine}
                    />
                    課題を解決した事例
                  </span>
                </h3>
                <AssessmentVoiceList slugs={["ubie", "crosswarp"]} />
              </div>

              <div id="skill" className={styles.VoiceRecommendation_Category}>
                <h3 className={styles.VoiceRecommendation_Heading}>
                  <span className={styles.VoiceRecommendation_HeadingEmoji}>
                    &#128640;
                  </span>
                  <span className={styles.VoiceRecommendation_HeadingText}>
                    提案・診断実施・報告書を通して
                    <br
                      className={styles.VoiceRecommendation_HeadingChangeLine}
                    />
                    高い技術力を実感した事例
                  </span>
                </h3>
                <AssessmentVoiceList slugs={["freee", "finatext"]} />
              </div>
              <Section background="subtle-gray">
                <VoicesLinkFooter />
              </Section>
            </Common2PaneSectionLayout>
          </div>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export default withContext(Component);
