// extracted by mini-css-extract-plugin
export var VoiceRecommendation = "index-module--VoiceRecommendation--bW6kF";
export var VoiceRecommendation_Body = "index-module--VoiceRecommendation_Body--CvbGb";
export var VoiceRecommendation_Category = "index-module--VoiceRecommendation_Category--FnMhx";
export var VoiceRecommendation_Consulting = "index-module--VoiceRecommendation_Consulting--2IQx0";
export var VoiceRecommendation_ConsultingDescription = "index-module--VoiceRecommendation_ConsultingDescription--SyLcj";
export var VoiceRecommendation_ConsultingHeading = "index-module--VoiceRecommendation_ConsultingHeading--5yMi0";
export var VoiceRecommendation_Head = "index-module--VoiceRecommendation_Head--PtrmW";
export var VoiceRecommendation_Heading = "index-module--VoiceRecommendation_Heading--f0ojv";
export var VoiceRecommendation_HeadingChangeLine = "index-module--VoiceRecommendation_HeadingChangeLine--uTaxr";
export var VoiceRecommendation_HeadingEmoji = "index-module--VoiceRecommendation_HeadingEmoji--FU7oh";
export var VoiceRecommendation_HeadingText = "index-module--VoiceRecommendation_HeadingText--iW8hA";
export var VoiceRecommendation_Title = "index-module--VoiceRecommendation_Title--B3PAC";
export var VoiceRecommendation_TitleDescription = "index-module--VoiceRecommendation_TitleDescription--3CFNW";
export var VoiceRecommendation_Toc = "index-module--VoiceRecommendation_Toc--AW7c5";
export var VoiceRecommendation_TocItem = "index-module--VoiceRecommendation_TocItem--LBCm2";
export var VoiceRecommendation_TocItemLink = "index-module--VoiceRecommendation_TocItemLink--Hp5WK";